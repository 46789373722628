body {
	font-family: 'DM Sans', sans-serif;
}

.gradient-stroke-text {
	font-family: 'DM Sans', sans-serif;

	
   padding: 2px ;
    color:  #070A0F;
    text-transform: capitalize;
	background: linear-gradient(-180deg, #FE4618, #FFFCFB);
    background-size: 100% 100%;
	-webkit-background-clip: text;
	-webkit-text-stroke: 4px transparent;
	
  }
/* What you’ll be learn */


.scroll::-webkit-scrollbar {
	display: none;
}

.space-bar {
	height: 200px;
	width: 100%;
	background: transparent;
}


.spacer {
	width: 100%;
	height: 100vh;
	background: #ddd;
}


.gallery h1 {
	font-size: clamp(2rem, 4vw, 3rem);
}

.gallery p {
	font-size: clamp(.1rem, 2.5vw, 2rem);
	line-height: 1.4;
}


.gallery {
	display: flex;
	/* outline:1px solid red; */
}

.left {
	width: 50%;
}

.right {
	

	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;

}

.desktopContent {
	margin: auto;
	width: 100%;
}

.desktopContentSection {
	min-height: 100vh;
	/* outline:1px solid green; */
	display: flex;
	flex-direction: column;
	justify-content: center;
}


.desktopPhotos {
	width: 25vw;
	height: 25vw;
	border-radius: 20px;
	position: relative;
	overflow: hidden;

	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4);
}

.desktopPhoto {
	position: absolute;
	width: 100%;
	height: 100%;

}
#vector path{
	stroke-dashoffset: 1000px;
}








.mobilePhoto {
	width: 80vw;
	height: 80vw;
	margin-bottom: 1rem;
	border-radius: 6vw;
}


.section {
	height: 100vh;
	padding: 0 clamp(4rem, 12vw, 20rem);
	display: grid;
	place-content: center;

	p {
		font-size: clamp(2rem, 5vw, 8rem);

	}

	&:nth-of-type(1) {
		border-bottom: 1px solid black;
	}

	&:nth-of-type(3) {
		background: #ffff00;
	}

	&:nth-of-type(4) {
		background: rgb(29, 29, 29);
		color: white;
	}
}


.svg-bg {
	height: 400vh;
	background: black;
	color: white;
}




.header-section {
	margin: 100px auto 0;
}









